import rem from './rem';

export const mediaSizes = {
  xxs: 320,
  xs: 480,
  s: 600,
  m: 768,
  l: 992,
  xl: 1100,
  xxl: 1200,
  xxxl: 1350,
} as const;

/**
 * Utility function to generate media queries
 *
 * Sizes: { xxs: 320, xs: 480, s: 600, m: 768, l: 992, xl: 1100, xxl: 1200, xxxl: 1350 }
 *
 * @param from "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl" | number
 * @example
 * ```javascript
 * const Wrapper = styled('div')`
 *   display: block;
 *   ${mb('m')} {
 *     display: none;
 *   }
 * `;
 * ```
 */
function mb(from: keyof typeof mediaSizes | number) {
  if (typeof from === 'string') {
    if (from in mediaSizes) {
      return `@media (min-width: ${rem(mediaSizes[from])})`;
    } else {
      throw new Error(
        `mb: Media size "${from}" is not defined. Use one of [${Object.keys(
          mediaSizes,
        )}]`,
      );
    }
  }

  if (typeof from !== 'number') {
    throw new Error(`mb: Invalid media breakpoint argument "${from}"`);
  }

  return `@media (min-width: ${rem(from)})`;
}

export default mb;
