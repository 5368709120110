import rem from '../utilities/rem';

type Color = string;
type Size = number;
type SizeString = string;
type Shadow = string;
type FontFamily = string;

export interface Theme {
  color: {
    base: Color;
    background: Color;
    primary: Color;
    primaryBg: Color;
    danger: Color;
    dangerBg: Color;
    warning: Color;
    warningBg: Color;
    success: Color;
    successBg: Color;
    fadedText: Color;
  };
  input: {
    height: Size;
    backgroundColor: Color;
    horizontalPadding: Size;
    disabledTextColor: Color;
    disabledBackground: Color;
    disabledCursor: string;
    readOnlyCursor: string;
    borderRadius: Size;
    borderColor: Color;
    borderWidth: Size;
    shadow: string;
  };
  checkbox: {
    checkedBackgroundColor: Color;
    checkColor: Color;
    disabledBackground: Color;
    disabledCheckColor: Color;
    borderRadius: Size;
    borderColor: Color;
    borderWidth: Size;
    shadow: string;
  };
  form: {
    labelColor: Color;
    placeholderColor: Color;
  };
  formGroup: {
    labelGutter: Size;
    bottomMargin: Size;
  };
  table: {
    headerBg: Color;
    evenRow: Color;
    evenRowBg: Color;
    oddRow: Color;
    oddRowBg: Color;
    hover: Color;
    activeRow: Color;
    activeRowBg: Color;
    highlightRow: Color;
    highlightRowBg: Color;
    cellHeight: Size;
  };
  tooltip: {
    textColor: Color;
    backgroundColor: Color;
    borderRadius: Size;
  };
  select: {
    placeholderColor: Color;
    darkerBg: Color;
    padding: Size;
  };
  grid: {
    gutterWidth: Size;
  };
  tabber: {
    active: Color;
    activeLight: Color;
    inactive: Color;
    activeText: Color;
    borderColor: Color;
  };
  colorControl: {
    darkColor: Color;
    lightColor: Color;
  };
  navigation: {
    backgroundColor: Color;
    itemTextColor: Color;
    subItemTextColor: Color;
    iconColor: Color;
    // active
    activeItemTextColor: Color;
    activeItemBackgroundColor: Color;
    activeSubItemTextColor: Color;
    activeSubItemBackgroundColor: Color;
    // activeItemIconColor: Color;
    // focused + hovered
    focusedItemTextColor: Color;
    focusedItemBackgroundColor: Color;
    focusedSubItemTextColor: Color;
    focusedSubItemBackgroundColor: Color;
    // focusedItemIconColor: Color;
    width: Size;
    shrunkWidth: Size;
    itemHeight: Size;
    subItemHeight: Size;
    verticalPadding: Size;
  };
  paginator: {
    backgroundColor: Color;
    activeColor: Color;
    disabledColor: Color;
    borderColor: Color;
    textColor: Color;
  };
  headerBar: {
    backgroundColor: Color;
    height: Size;
  };
  contextBar: {
    backgroundColor: Color;
    height: Size;
  };
  textColor: Color;
  inactiveColor: Color;
  separatorColor: Color;
  baseBorderRadius: SizeString;
  largeBorderRadius: SizeString;
  fontFamily: FontFamily;
  backgroundColor: Color;
  shadow: Shadow;
}

type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

export const getTheme = (override: RecursivePartial<Theme> = {}): Theme => {
  const color = {
    base: '#000',
    background: '#3A3D46',
    primary: '#FF002C',
    primaryBg: '#FFBDC7',
    danger: '#e94141',
    dangerBg: '#FDEAE9',
    warning: '#ca9727',
    warningBg: '#F9F2E2',
    success: '#5bd084',
    successBg: '#EDFAF2',
    fadedText: '#888',
    ...override.color,
  };

  const input = {
    height: 48,
    horizontalPadding: 12,
    backgroundColor: '#fff',
    disabledTextColor: color.fadedText,
    disabledBackground: '#F9F9F9',
    disabledCursor: 'not-allowed',
    readOnlyCursor: 'not-allowed',
    borderRadius: 4,
    borderColor: '#bbb',
    borderWidth: 1.5,
    shadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.16)',
    ...override.input,
  };

  const form = {
    labelColor: '#000',
    placeholderColor: color.fadedText,
    ...override.form,
  };

  const formGroup = {
    labelGutter: 5,
    bottomMargin: 8,
    ...override.formGroup,
  };

  const select = {
    placeholderColor: color.fadedText,
    darkerBg: '#F9F9F9',
    padding: 2,
    ...override.select,
  };

  const table = {
    headerBg: '#f9f9f9',
    evenRow: color.base,
    evenRowBg: '#f9f9f9',
    oddRow: color.base,
    oddRowBg: '#ffffff',
    hover: '#f2f2f2',
    activeRow: color.base,
    activeRowBg: color.primaryBg,
    highlightRow: color.base,
    highlightRowBg: color.successBg,
    cellHeight: 42,
    ...override.table,
  };

  const grid = {
    gutterWidth: 24,
    ...override.grid,
  };

  const tabber = {
    active: color.primary,
    activeLight: color.primaryBg,
    inactive: color.fadedText,
    activeText: color.base,
    borderColor: color.fadedText,
    ...override.tabber,
  };

  const colorControl = {
    darkColor: color.base,
    lightColor: '#fff',
    ...override.colorControl,
  };

  const theme = {
    textColor: override.textColor || color.base,
    inactiveColor: override.inactiveColor || '#b5b5b5',
    separatorColor: override.separatorColor || '#eee',
    baseBorderRadius: override.baseBorderRadius || rem(2),
    largeBorderRadius: override.largeBorderRadius || rem(4),
    fontFamily: override.fontFamily || "'Open Sans', sans-serif",
    backgroundColor: override.backgroundColor || '#fff',
    shadow: override.shadow || `0 0 ${rem(40)} 0 rgba(0, 0, 0, 0.2)`,
  };

  const paginator = {
    backgroundColor: theme.backgroundColor,
    activeColor: '#000',
    disabledColor: color.fadedText,
    borderColor: theme.separatorColor,
    textColor: theme.textColor,
    ...override.paginator,
  };

  const checkbox = {
    checkedBackgroundColor: color.primary,
    checkColor: '#fff',
    disabledBackground: '#f7f7f7',
    disabledCheckColor: color.fadedText,
    borderRadius: 4,
    borderColor: 'rgba(187, 187, 187, 1)',
    borderWidth: 1.5,
    shadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.16)',
    ...override.checkbox,
  };

  const tooltip = {
    textColor: '#fff',
    backgroundColor: '#333',
    borderRadius: 4,
    ...override.tooltip,
  };

  const navigation = {
    backgroundColor: theme.backgroundColor,
    itemTextColor: theme.textColor,
    subItemTextColor: theme.textColor,
    iconColor: color.primary,
    // active
    activeItemTextColor: color.primary,
    activeItemBackgroundColor: 'transparent',
    activeSubItemTextColor: color.primary,
    activeSubItemBackgroundColor: 'transparent',
    // activeItemIconColor: color.primary,
    // focused + hovered
    focusedItemTextColor: '',
    focusedItemBackgroundColor: color.primaryBg,
    focusedSubItemTextColor: '',
    focusedSubItemBackgroundColor: color.primaryBg,
    // focusedItemIconColor: theme.textColor,
    width: 240,
    shrunkWidth: 48 + 1, // width + 1px border
    itemHeight: 48,
    subItemHeight: 32,
    verticalPadding: 8,
    ...override.navigation,
  };

  const headerBar = {
    height: 56,
    backgroundColor: theme.backgroundColor,
    ...override.headerBar,
  };

  const contextBar = {
    height: 56,
    backgroundColor: theme.backgroundColor,
    ...override.contextBar,
  };

  return {
    ...theme,
    color,
    colorControl,
    form,
    formGroup,
    table,
    tooltip,
    select,
    input,
    checkbox,
    grid,
    tabber,
    paginator,
    navigation,
    headerBar,
    contextBar,
  };
};

export default getTheme();
