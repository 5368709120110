import Action from './Action';
import Header from './Header';
import Item from './Item';
import React from 'react';
import Spacer from './Spacer';
import rem from '../../utilities/rem';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  background-color: transparent;
  padding: ${rem(24)};
  display: flex;
  align-items: center;
  border-bottom: ${rem(1)} solid #dddddd;
  color: ${({ theme }) => theme.textColor};
  position: relative;
  z-index: 1;
`;

interface ISubComponents {
  Spacer: typeof Spacer;
  Item: typeof Item;
  Header: typeof Header;
  Action: typeof Action;
}

interface IOwnProps {
  className?: string;
  children?: React.ReactNode;
}

type IProps = IOwnProps;

const HeaderBar: React.FC<IProps> & ISubComponents = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

HeaderBar.Spacer = Spacer;
HeaderBar.Item = Item;
HeaderBar.Header = Header;
HeaderBar.Action = Action;

export default HeaderBar;
