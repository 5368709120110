import React from 'react';

const PageLoader: React.FC = () => {
  React.useEffect(() => {
    const loader = document.getElementById('loader');
    if (loader) {
      loader.style.zIndex = '1000';
    }

    return () => {
      const loader = document.getElementById('loader');
      if (loader) {
        loader.style.zIndex = '-1';
      }
    };
  }, []);
  return null;
};

export default PageLoader;
