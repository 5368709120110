import { ISidebarDescriptor } from './components/Sidebar/index';
import React, { createContext } from 'react';

interface IAppContext {
  authComponentLoaded: boolean;
  setAuthComponentLoaded: (value: boolean) => void;
  currentAppTitle: string;
  setCurrentAppTitle: (value: string) => void;
  currentProfileTitle: string;
  setCurrentProfileTitle: (value: string) => void;
  currentProfileWidgetId: string;
  setCurrentProfileWidgetId: (value: string) => void;
  leftSidebarPlaceholderRef: React.RefObject<HTMLDivElement>;
  rightSidebarPlaceholderRef: React.RefObject<HTMLDivElement>;
  attachSidebar: (sd: ISidebarDescriptor) => void;
  detachSidebar: (sd: ISidebarDescriptor) => void;
  updateSidebar: (sd: ISidebarDescriptor) => void;
  attachedSidebars: ISidebarDescriptor[];
}

const initialValues: IAppContext = {
  authComponentLoaded: false,
  setAuthComponentLoaded: (value: boolean) => {},
  currentAppTitle: '',
  setCurrentAppTitle: (value: string) => {},
  currentProfileTitle: '',
  setCurrentProfileTitle: (value: string) => {},
  currentProfileWidgetId: '',
  setCurrentProfileWidgetId: (value: string) => {},
  leftSidebarPlaceholderRef: React.createRef(),
  rightSidebarPlaceholderRef: React.createRef(),
  attachSidebar: (sd: ISidebarDescriptor) => {},
  detachSidebar: (sd: ISidebarDescriptor) => {},
  updateSidebar: (sd: ISidebarDescriptor) => {},
  attachedSidebars: [],
};

const AppContext = createContext(initialValues);

export default AppContext;
