import baseTheme, { Theme } from './baseTheme';
import fluidTypography from '../utilities/fluidTypography';

export const fontSize = {
  text: {
    min: 12,
    max: 14,
  },
  subtitle: {
    min: 14,
    max: 16,
  },
  title: {
    min: 18,
    max: 20,
  },
  appTitle: {
    min: 20,
    max: 24,
  },
};

export const fluidFontSize = (
  type: keyof typeof fontSize,
  from: number,
  to: number
) => fluidTypography(fontSize[type].min, fontSize[type].max, from, to);

export const theme = {
  ...baseTheme,
};

export interface ThemeInterface extends Theme {}
