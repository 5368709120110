const cssRegex = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/;

const stripUnit = (value: string | number): string | number => {
  if (typeof value !== 'string') return value;
  const matchedValue = value.match(cssRegex);
  return matchedValue ? parseFloat(value) : value;
};

const pxtoFactory =
  (to: string) =>
  (pxval: string | number, base: string | number = '16px'): string => {
    let newPxval = pxval;
    let newBase = base;
    if (typeof pxval === 'string') {
      newPxval = stripUnit(pxval);
    }

    if (typeof base === 'string') {
      newBase = stripUnit(base);
    }

    return `${Number(newPxval) / Number(newBase)}${to}`;
  };

const rem: (value: string | number, base?: string | number) => string =
  pxtoFactory('rem');

export default rem;
