import rem from '../../utilities/rem';
import styled from 'styled-components/macro';

export default styled.div`
  height: 100%;
  margin: 0 ${rem(4)};
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
