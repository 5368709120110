import Button from '../Button';
import React from 'react';
import rem from '../../utilities/rem';
import styled from 'styled-components/macro';

const Wrapper = styled(Button)`
  margin: 0 ${rem(4)};
  padding: 0 ${rem(8)};
  min-height: ${rem(40)};
  min-width: ${rem(40)};
  height: 100%;
`;

interface IOwnProps {
  disabled?: boolean;
  className?: string;
  href?: string;
  to?: string;
  as?: any;
  onClick?: () => void;
  [key: string]: any;
}

export type IProps = IOwnProps;

const Action: React.FC<IProps> = ({ children, as, ...props }) => {
  return (
    <Wrapper {...props} forwardedAs={as}>
      {children}
    </Wrapper>
  );
};

export default Action;
