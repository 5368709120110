import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { createRoot } from 'react-dom/client';
import { theme } from './theme/theme';
import App from './App';
import Cookies from 'js-cookie';
import CoreApi from './api/CoreApi';
import config from './config';

if (process.env.REACT_APP_CORE_API_BASE_URL) {
  CoreApi.setBaseUrl(String(process.env.REACT_APP_CORE_API_BASE_URL));
  const accessToken = Cookies.get(config.cookies.accessToken);
  if (accessToken) {
    CoreApi.setToken(accessToken);
  }
} else {
  throw new Error('REACT_APP_CORE_API_BASE_URL not set');
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>,
);
