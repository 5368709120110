import React from 'react';
import rem from '../../utilities/rem';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.textColor};
  opacity: 1;
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: ${rem(13)};
  color: ${({ theme }) => theme.color.fadedText};
  margin-top: ${rem(2)};
`;

interface IOwnProps {
  className?: string;
  subtitle?: string | JSX.Element;
  children?: React.ReactNode;
}

export type IProps = IOwnProps;

const Header: React.FC<IProps> = ({ children, className, subtitle }) => {
  return (
    <Wrapper className={className}>
      <Title>{children}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  );
};

export default Header;
