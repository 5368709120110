import { ApiError } from './library/CoreApi';
import { QueryClient } from 'react-query';
import Cookies from 'js-cookie';
import config from './config';

export const makeClient = (opts: any = {}) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000, // 5 min,
        cacheTime: 5 * 60 * 1000, // 5 min,
        retry: false,
        ...opts,
        onError(err: ApiError) {
          if (err?.details?.statusCode === 401) {
            Cookies.remove(config.cookies.accessToken);
            window.location.reload();
          }
        },
      },
    },
  });
