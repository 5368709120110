import AppContext from '../../context';
import Close from '../Icons/close';
import HeaderBar from '../HeaderBar';
import React from 'react';
import ReactDOM from 'react-dom';
import mb from '../../utilities/mb';
import rem from '../../utilities/rem';
import styled, { css } from 'styled-components/macro';
import uniqueId from 'lodash.uniqueid';

const BASE_ZINDEX = 20;
let zIndexCounter = 0;

export const SIDEBAR_PUSH_THRESHOLD_MEDIA_QUERY = 'xl';
export const SIDEBAR_WIDTH = 336;
export const SIDEBAR_TRANSITION = '0.2s ease-in-out';
export const SIDEBAR_BACKDROP_BACKGROUND = 'rgba(0, 0, 0, 0.25)';

const FixedWrapper = styled.div<{ $visible: boolean; $zIndex: number }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: ${({ $zIndex }) => BASE_ZINDEX + $zIndex};
  ${mb('m')} {
    border-left: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  }
  ${({ $visible }) => {
    if ($visible) {
      return css`
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      `;
    }
    return css`
      top: 100%;
      right: 0;
      bottom: -100%;
      left: 0;
    `;
  }} height: 100%;
  background: white;
  transition: all ${SIDEBAR_TRANSITION};
  ${mb('m')} {
    ${({ $visible }) =>
      $visible
        ? css`
            top: 0;
            left: calc(100% - ${rem(SIDEBAR_WIDTH)});
          `
        : css`
            top: 0;
            left: 100%;
          `}
  }
`;

interface IStaticWrapperProps {
  $visible: boolean;
  $zIndex: number;
  $position?: ISidebarPosition;
}

const StaticWrapper = styled.div<IStaticWrapperProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: ${({ $zIndex }) => BASE_ZINDEX + $zIndex};
  transition: all ${SIDEBAR_TRANSITION};
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: ${rem(16)} 0 0 ${rem(16)};

  ${({ $visible, $position }) =>
    $position === 'right'
      ? css`
          top: 0;
          bottom: 0;
          right: ${$visible ? 0 : '-100%'};
          width: 100%;
          width: ${rem(SIDEBAR_WIDTH)};
          box-shadow: 0 ${rem(2)} ${rem(16)} rgba(0, 0, 0, 0.08);
        `
      : css`
          top: 0;
          bottom: 0;
          left: ${$visible ? 0 : '-100%'};
          width: ${rem(SIDEBAR_WIDTH)};
          box-shadow: 0 ${rem(2)} ${rem(16)} rgba(0, 0, 0, 0.08);
        `}
`;

const ScrollContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 0 0;
`;

interface ISidebarBackdrop {
  isOpen: boolean;
  zIndex: number;
  modal: boolean;
}
const SidebarBackdrop = styled.div<ISidebarBackdrop>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ zIndex }) => BASE_ZINDEX + zIndex};
  background-color: ${SIDEBAR_BACKDROP_BACKGROUND};
  transition: all ${SIDEBAR_TRANSITION};
  ${({ isOpen, modal }) => {
    if (!modal) {
      if (isOpen) {
        return css`
          opacity: 1;
          ${mb(SIDEBAR_PUSH_THRESHOLD_MEDIA_QUERY)} {
            pointer-events: none;
            opacity: 0;
          }
        `;
      } else {
        return css`
          pointer-events: none;
          opacity: 0;
        `;
      }
    } else {
      if (isOpen) {
        return css`
          opacity: 1;
        `;
      } else {
        return css`
          pointer-events: none;
          opacity: 0;
        `;
      }
    }
  }}
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export type ISidebarPosition = 'right' | 'left';
export interface ISidebarDescriptor {
  id: string;
  opened?: boolean;
  modal?: boolean;
  position?: ISidebarPosition;
}

interface OwnProps {
  header?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  visible?: boolean;
  modal?: boolean;
  position?: ISidebarPosition;
}

type Props = OwnProps;

interface IState {
  zIndex: number;
  initializing: boolean;
}

class Sidebar extends React.PureComponent<Props, IState> {
  static defaultProps = {
    visible: false,
    modal: false,
    position: 'right',
  };

  static contextType = AppContext;

  id: string;

  constructor(props: Props) {
    super(props);
    this.id = uniqueId('sidebar');
    this.state = {
      zIndex: 0,
      initializing: true,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const ctx = this.context as React.ContextType<typeof AppContext>;
    this.setState({ zIndex: ++zIndexCounter, initializing: false });
    ctx.attachSidebar({
      id: this.id,
      opened: this.props.visible!,
      modal: this.props.modal!,
      position: this.props.position,
    });
  };

  destroy = () => {
    const ctx = this.context as React.ContextType<typeof AppContext>;
    ctx.detachSidebar({
      id: this.id,
    });
  };

  update = () => {
    const ctx = this.context as React.ContextType<typeof AppContext>;
    ctx.updateSidebar({
      id: this.id,
      opened: false,
      modal: this.props.modal!,
      position: this.props.position,
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.visible && this.props.visible) {
      this.init();
    } else if (prevProps.visible && !this.props.visible) {
      this.update();
    }
    if (prevProps.modal !== this.props.modal) {
      this.update();
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  render() {
    const { header, onClose, visible, children, modal, position } = this.props;
    const { zIndex, initializing } = this.state;

    if (initializing) {
      return null;
    }

    const content = (
      <>
        {header && (
          <HeaderBar>
            <HeaderBar.Header>{header}</HeaderBar.Header>
            <HeaderBar.Spacer />
            {onClose && (
              <CloseButton onClick={onClose}>{Close(24)}</CloseButton>
            )}
          </HeaderBar>
        )}
        <ScrollContent>{children}</ScrollContent>
      </>
    );

    return (
      <AppContext.Consumer>
        {(context) => {
          if (context) {
            const container: HTMLDivElement =
              position === 'left'
                ? (context.leftSidebarPlaceholderRef.current as HTMLDivElement)
                : (context.rightSidebarPlaceholderRef
                    .current as HTMLDivElement);

            if (container) {
              return ReactDOM.createPortal(
                <>
                  <SidebarBackdrop
                    zIndex={zIndex}
                    isOpen={visible!}
                    onClick={() => {
                      if (onClose) {
                        onClose();
                      }
                    }}
                    modal={modal!}
                  />
                  <StaticWrapper
                    $zIndex={zIndex}
                    $visible={visible!}
                    $position={position}
                  >
                    {content}
                  </StaticWrapper>
                </>,
                container,
              );
            }
          }

          // rendering with fixed position (legacy)
          return (
            <>
              <SidebarBackdrop
                zIndex={zIndex}
                isOpen={visible!}
                modal={modal!}
              />
              <FixedWrapper $zIndex={zIndex} $visible={visible!}>
                {content}
              </FixedWrapper>
            </>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default Sidebar;
