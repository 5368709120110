import { fluidFontSize } from '../../theme/theme';
import { mediaSizes } from '../../utilities/mb';
import Loader from '../Loader';
import React from 'react';
import rem from '../../utilities/rem';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.button<{
  inline?: boolean;
  compact?: boolean;
  danger?: boolean;
  success?: boolean;
  basic?: boolean;
  circular?: boolean;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.color.primary};
  border-width: 0;
  color: #fff;
  ${({danger, success, basic}) => {
    if (!danger && !success && !basic) {
      return css`
      &:hover {
        background-color: #e62334;
      }
      `
    }
    return css``
  }}
  ${({ danger }) =>
    danger &&
    css`
      background-color: ${({ theme }) => theme.color.danger};
    `}
  ${({ success }) =>
    success &&
    css`
      background-color: ${({ theme }) => theme.color.success};
    `}
    ${({ basic }) =>
    basic &&
    css`
      background: #ffffff;
      border: ${rem(1.5)} solid #bbbbbb;
      color: #000;
    `}
  ${({ inline }) =>
    !!inline
      ? css`
          width: auto;
          padding: 0 ${rem(24)};
        `
      : css`
          width: 100%;
          padding: 0 ${rem(16)};
        `}
  ${({ compact }) =>
    !!compact &&
    css`
      padding: 0 ${rem(12)};
    `}

  border-radius: ${rem(4)};
  ${({ circular }) =>
    !!circular &&
    css`
      border-radius: ${rem(24)};
    `}
  text-decoration: underline;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${fluidFontSize('text', mediaSizes['l'], 1920)};
  font-weight: bold;
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.16);
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  height: ${rem(48)};
  &:disabled {
    cursor: not-allowed;
    background: rgb(181, 181, 181);
    color: #fff;
    border-color: rgb(181, 181, 181);
  }
  transition: all 0.3s linear;
  &:hover {
    box-shadow: none;
  }
`;

const Content = styled('div')<{ $isLoading: boolean }>`
  transition: opacity 0.2s;
  opacity: ${({ $isLoading }) => ($isLoading ? 0.25 : 1)};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const StyledLoader = styled(Loader)<{ loading: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.2s, opacity 0.2s;
  transform-origin: left top;
  ${({ loading }) => {
    if (loading) {
      return css`
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
      `;
    }
    return css`
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    `;
  }};
`;

type OwnProps = {
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  inline?: boolean;
  className?: string;
  compact?: boolean;
  danger?: boolean;
  success?: boolean;
  basic?: boolean;
  circular?: boolean;
  disabled?: boolean;
};

type Props = OwnProps;

const Button: React.FC<Props> = ({
  children,
  loading = false,
  type = 'button',
  onClick: providedOnClick,
  inline = false,
  className,
  compact = false,
  danger = false,
  success = false,
  basic = false,
  circular = false,
  disabled = false,
}) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (loading) {
      e.preventDefault();
    } else if (providedOnClick) {
      providedOnClick(e);
    }
  };

  return (
    <Wrapper
      compact={compact}
      className={className}
      inline={inline}
      danger={danger}
      success={success}
      basic={basic}
      type={type}
      onClick={onClick}
      circular={circular}
      disabled={disabled}
    >
      <Content $isLoading={loading!}>{children}</Content>
      <StyledLoader
        color={basic ? '#bbb' : '#fff'}
        size="l"
        loading={loading!}
      />
    </Wrapper>
  );
};

export default Button;
