const config = {
  cookies: {
    accessToken: 'access_token',
    guardianAccessToken: 'guardian_access_token',
  },
  lists: {
    invitations: {
      limit: 20,
    },
    messages: {
      limit: 20,
    },
  },
};

export default config;
