import * as React from 'react';
import rem from '../../utilities/rem';
import styled from 'styled-components/macro';

const Wrapper = styled('div')<{ $scSize: number }>`
  height: ${({ $scSize }) => `${rem($scSize)}`};
  width: ${({ $scSize }) => `${rem($scSize)}`};
`;

const Inner = styled('div')<{ $scSize: number; $scColor?: string | null }>`
  height: 100%;
  width: 100%;
  animation: rotate 1s infinite linear;
  border: ${({ theme, $scSize, $scColor }) =>
    `${rem($scSize / 6.5)} solid ${
      $scColor !== null ? $scColor : theme.color.primary
    }`};
  border-right-color: transparent;
  border-radius: 50%;

  @keyframes rotate {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const ContentWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface OwnProps {
  size?: 'm' | 'l' | 'xl' | 'xxl' | number;
  className?: string;
  color?: string | null;
}

type Props = OwnProps;

export const Loader: React.FC<Props> = ({
  size = 'm',
  color = null,
  className = '',
}) => {
  const sizeMapping = {
    m: 16,
    l: 24,
    xl: 40,
    xxl: 110,
  };
  const sizeName =
    size! in sizeMapping
      ? (sizeMapping as any)[size!]
      : typeof size === 'number'
      ? size
      : 16;
  return (
    <Wrapper className={className} $scSize={sizeName} data-testid="Loader">
      <Inner $scSize={sizeName} $scColor={color} />
    </Wrapper>
  );
};

export const ContentLoader: React.FC<Props> = ({ className, ...restProps }) => {
  return (
    <ContentWrapper className={className}>
      <Loader {...restProps} />
    </ContentWrapper>
  );
};

export default Loader;
